body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes loader-dots {
  0% {
    box-shadow: #00f0fd 0 -30px 0 7px, #dadada 0 -30px 0 7px,
      #dadada 26px -15px 0 7px, #dadada 26px 15px 0 7px, #dadada 0 30px 0 7px,
      #dadada -26px 15px 0 7px, #dadada -26px -15px 0 7px;
  }
  8.33% {
    box-shadow: #00f0fd 26px -15px 0 7px, #dadada 26px -15px 0 7px,
      #dadada 26px -15px 0 7px, #dadada 26px 15px 0 7px, #dadada 0 30px 0 7px,
      #dadada -26px 15px 0 7px, #dadada -26px -15px 0 7px;
  }
  16.67% {
    box-shadow: #00f0fd 26px 15px 0 7px, #dadada 26px 15px 0 7px,
      #dadada 26px 15px 0 7px, #dadada 26px 15px 0 7px, #dadada 0 30px 0 7px,
      #dadada -26px 15px 0 7px, #dadada -26px -15px 0 7px;
  }
  25% {
    box-shadow: #00f0fd 0 30px 0 7px, #dadada 0 30px 0 7px, #dadada 0 30px 0 7px,
      #dadada 0 30px 0 7px, #dadada 0 30px 0 7px, #dadada -26px 15px 0 7px,
      #dadada -26px -15px 0 7px;
  }
  33.33% {
    box-shadow: #00f0fd -26px 15px 0 7px, #dadada -26px 15px 0 7px,
      #dadada -26px 15px 0 7px, #dadada -26px 15px 0 7px,
      #dadada -26px 15px 0 7px, #dadada -26px 15px 0 7px,
      #dadada -26px -15px 0 7px;
  }
  41.67% {
    box-shadow: #00f0fd -26px -15px 0 7px, #dadada -26px -15px 0 7px,
      #dadada -26px -15px 0 7px, #dadada -26px -15px 0 7px,
      #dadada -26px -15px 0 7px, #dadada -26px -15px 0 7px,
      #dadada -26px -15px 0 7px;
  }
  50% {
    box-shadow: #00f0fd 0 -30px 0 7px, #dadada 0 -30px 0 7px,
      #dadada 0 -30px 0 7px, #dadada 0 -30px 0 7px, #dadada 0 -30px 0 7px,
      #dadada 0 -30px 0 7px, #dadada 0 -30px 0 7px;
  }
  58.33% {
    box-shadow: #00f0fd 26px -15px 0 7px, #dadada 0 -30px 0 7px,
      #dadada 26px -15px 0 7px, #dadada 26px -15px 0 7px,
      #dadada 26px -15px 0 7px, #dadada 26px -15px 0 7px,
      #dadada 26px -15px 0 7px;
  }
  66.67% {
    box-shadow: #00f0fd 26px 15px 0 7px, #dadada 0 -30px 0 7px,
      #dadada 26px -15px 0 7px, #dadada 26px 15px 0 7px, #dadada 26px 15px 0 7px,
      #dadada 26px 15px 0 7px, #dadada 26px 15px 0 7px;
  }
  75% {
    box-shadow: #00f0fd 0 30px 0 7px, #dadada 0 -30px 0 7px,
      #dadada 26px -15px 0 7px, #dadada 26px 15px 0 7px, #dadada 0 30px 0 7px,
      #dadada 0 30px 0 7px, #dadada 0 30px 0 7px;
  }
  83.33% {
    box-shadow: #00f0fd -26px 15px 0 7px, #dadada 0 -30px 0 7px,
      #dadada 26px -15px 0 7px, #dadada 26px 15px 0 7px, #dadada 0 30px 0 7px,
      #dadada -26px 15px 0 7px, #dadada -26px 15px 0 7px;
  }
  91.67% {
    box-shadow: #00f0fd -26px -15px 0 7px, #dadada 0 -30px 0 7px,
      #dadada 26px -15px 0 7px, #dadada 26px 15px 0 7px, #dadada 0 30px 0 7px,
      #dadada -26px 15px 0 7px, #dadada -26px -15px 0 7px;
  }
  100% {
    box-shadow: #00f0fd 0 -30px 0 7px, #dadada 0 -30px 0 7px,
      #dadada 26px -15px 0 7px, #dadada 26px 15px 0 7px, #dadada 0 30px 0 7px,
      #dadada -26px 15px 0 7px, #dadada -26px -15px 0 7px;
  }
}

.loader-dots:not(:required) {
  overflow: hidden;
  position: relative;
  text-indent: -9999px;
  display: inline-block;
  width: 7px;
  height: 7px;
  background: transparent;
  border-radius: 100%;
  box-shadow: #00f0fd 0 -30px 0 7px, #dadada 26px -15px 0 7px,
    #dadada 26px 15px 0 7px, #dadada 0 30px 0 7px, #dadada 0 30px 0 7px,
    #dadada 0 30px 0 7px;
  animation: loader-dots 3s infinite ease-in-out;
  transform-origin: 50% 50%;
}
