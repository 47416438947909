.navItemContainer {
  .popover {
    display: none;
    position: absolute;
    height: auto;
    width: 235px;
    z-index: 999999;
    left: 165px;
    box-shadow: 3px 3px 3px 0px #aaaaaa;

    &.campaigns {
      margin-top: 175px;
    }

    &.collapsed {
      left: 72px;
    }

    &:hover {
      display: block;
    }
  }

  &.target:hover {
    background-color: #28385e;

    .popover {
      display: block;
    }
  }

  .tabNavLink {
    color: #ffffff;
    text-decoration: inherit;
    stroke: rgba(0, 0, 0, 0.66);
    opacity: 0.5;
    font-weight: 400;

    &.direct {
      display: flex;
      flex-grow: 1;
      width: 100%;
    }

    &.active {
      color: white;
      stroke: white;
      font-weight: bolder;
      opacity: 1;
    }
  }

  .childNavLink {
    color: #666;
    stroke: #666;
    text-decoration: none;
    background-color: white;
    display: block;

    &:hover {
      background-color: #f6f7f8;
    }

    &.active {
      font-weight: bolder;
    }
  }

}
