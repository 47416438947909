.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
}

div:focus {
  outline: none;
}

button[role='tab'] {
  flex-grow: 1;
}

button[role='tab'][aria-selected='true'] > div {
  border-width: 4px;
}

button[role='tab'] > div {
  padding: 15px;
}
